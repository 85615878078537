/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){
    
      

      //Anchor animations
      $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        if(the_id !== '#') {

          $('html, body').animate({
            scrollTop: $(the_id).offset().top-60
          }, 'slow');

        }
        return false;

      });
      
      //Navigation
      $('.menu-bt').on('click', function() {
        $('body').toggleClass('no-scroll');
        $('#main-navigation').toggleClass('visible');
        $(this).toggleClass('open');
      });


      //Homepage
      $(window).on('scroll', function() {
        scrollPosition = $(this).scrollTop();
        if (scrollPosition >= 50) {
          $('#main-header').addClass('scrolled');
        }else {              
          $('#main-header').removeClass('scrolled');
        }
      });
      

      // Sliders
      $('.hero-slider').slick({
        dots: false,
        arrows: false,
        fade: true,
      });


      $('.full-width-slider').slick({
        dots: false,
        arrows: true,
        fade: true,
      });


      $('.image-slider').slick({
        dots: true,
        arrows: false,
        fade: true
      });

      $('.type-unite-slider').slick({
        arrows: true,
        infinite: false,
        slidesToShow: 2,
         responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
      });

      if($(window).width() < 650){
        if($('.types-unites').length > 0) {

          $('.types-unites').slick({
            arrows: false,
            dots: false,
            infinite: false,
          });
        }
      }

      //Select
      $('.select-wrapper').each(function() {

        var options = "";

        $(this).find('select option').each(function() {
          var option_value = $(this).text();

          if($(this).attr('data-floor')){
            var floor = $(this).attr('data-floor');            
          }

          if(option_value){

            if(floor){
              options = options+"<li value='"+floor+"'>"+option_value+"</li>";
            }else {
              options = options+"<li value='"+option_value+"'>"+option_value+"</li>";
            }
          }

        });

        if($(this).hasClass('form')){
          $(this).append("<div class='fake-select'><p class='title'>Tous les étages</p><div class='options'><ul>"+options+"</ul></div></div>");
        }else {
          $(this).append("<div class='fake-select'><p class='title'>Unité(s) Souhaitée(s)</p><div class='options'><ul>"+options+"</ul></div></div>");
        }

        

        var current = $(this);

        $(this).find('.title').on('click', function() {
          current.find('.options').slideToggle(); 
        });

        $(this).find('.options li').on('click', function() {
          var value = $(this).text();

          current.find('select option:contains("'+value+'")').prop('selected', true);

          current.find('.fake-select .title').text(value);

          $('.selDiv option:eq(1)').prop('selected', true);

          $(this).parent().parent().slideUp();
        }); 

      });


    $('#type-unite-form').on('submit', function() {

     /* var headerHeight = getHeaderHeight();

      $('html, body').animate({
            scrollTop: $('#type-unite-list').offset().top-headerHeight
          }, 'slow');*/

      return false;
    });

    $('.filter-action').on('click', openCloseFilter);

    //Filtre unites
    $('#filter-form').on('click', refreshUniteGrid);

    $('#type-unite-list .radio').on('click', function() {
      $(this).toggleClass('active');

      refreshUniteGrid();

    });

    $('.reset-type').on('click', function() {
      $('#type-unite-form .radio.active').each(function() {
        $(this).removeClass('active');

        refreshUniteGrid();
      });
   
    });

    if(lightbox) {

        lightbox.option({
          'albumLabel': "Image %1 de %2"
        });
    }


    $('#floor-1').on('click', function() {
      openFloorModal(1);
    });


    $('#floor-2').on('click', function() {
      openFloorModal(2);
    });


    $('#floor-3').on('click', function() {
      openFloorModal(3);
    });


    $('#floor-4').on('click', function() {
      openFloorModal(4);
    });


    $('#floor-5').on('click', function() {
      openFloorModal(5);
    });


    $('#floor-6').on('click', function() {
      openFloorModal(6);
    });


    $('#floor-7').on('click', function() {
      openFloorModal(7);
    });


    $('#modal-plan .close-modal').on('click', function() {
      closeFloorModal();
    });


    //

    ScrollReveal().reveal('.header-fade', { duration: 1000 });
    ScrollReveal().reveal('.header-fade-500', { duration: 1000, delay: 500 });
    ScrollReveal().reveal('.header-fade-scale', { duration: 1000, delay: 500, scale: 0.98 });

    ScrollReveal().reveal('.fade', { delay: 500, duration: 1000 });
    ScrollReveal().reveal('.fade-up', { delay: 750, duration: 1000, distance: "30px" });
    ScrollReveal().reveal('.fade-scale-up', { delay: 750, scale: 0.98, distance: "30px", duration: 1000 });

    $('body').addClass('loaded');
  }

  function openFloorModal(floorId) {

    $('#modal-plan').addClass('visible');

    $('#etage-'+floorId).fadeIn();
    $('#etage-'+floorId).addClass('visible');

  }

  function closeFloorModal(){
    $('.etage.visible').fadeOut();
    $('.etage.visible').removeClass('visible');

    $('#modal-plan').addClass('visibility-hidden');

    setTimeout(function() {
      $('#modal-plan').removeClass('visibility-hidden');
      $('#modal-plan').removeClass('visible');
    }, 600);

    
  }

  function refreshUniteGrid() {
    
    var count = 0;

    hideUniteGrid();

    setTimeout(function() {

      //Type unité
      var uniteActive = [];

      $('#type-unite-form .radio.active').each(function() {
        var type_id = $(this).attr('data-id');

        uniteActive.push(type_id);

      });


      //Étage
      var selectedFloor = $('#floor-selector').val();

      $('#results .unite').each(function() {
        var floor = $(this).find('.unite-wrapper').attr('data-floor');
        var type_unite = $(this).find('.unite-wrapper').attr('data-type');

        if(selectedFloor == '-1'){
          
          if(uniteActive.length == 0){
            $(this).slideDown();
            count++;
          }else {

            if(jQuery.inArray(type_unite, uniteActive) !== -1){
              $(this).slideDown();
              count++;
            }else {
              $(this).slideUp();
            }
          }
          
        }else {

          if(uniteActive.length == 0){

              if(selectedFloor == floor) {
                $(this).slideDown();
                count++;
              }else {
                $(this).slideUp();
              }

          }else {
            if(selectedFloor == floor && jQuery.inArray(type_unite, uniteActive) !== -1){
              $(this).slideDown();
              count++;
            }else {
              $(this).slideUp();
            }
          }

        }

      });

      setTimeout(function() {
        $('#found-post').text(count);
        showUniteGrid();
      }, 600);

    }, 600);

  }


  function hideUniteGrid() {
    $('.result-title').css('opacity', 0);
    $('.unites-list').css('opacity', 0);
  }

  function showUniteGrid() {
    $('.result-title').css('opacity', 1);
    $('.unites-list').css('opacity', 1);
  }


  function getHeaderHeight() {
    return $('#header-wrapper').outerHeight();
  }

  function openCloseFilter() {
    var label = $('.filter-action p').text();

    if(label == "fermer les filtres"){
      $('.filter-action p').text("ouvrir les filtres");
    }else {
      $('.filter-action p').text("fermer les filtres");
    }

    $('.form-filter').slideToggle();
  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
